<template>
    <div class="express">
        <!-- 头部banner -->
        <banner :subtitle="subtitle" :maintitle="maintitle" :logoFlag="logoFlag" :imgSrc="imgSrc"></banner>
        <!-- 主体内容 -->
        <div class="express_cont">
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="用车体验" subtitle="Experience"></partTitle>
                <div>
                    <swiper class="swiper first" :options="swiperOption">
                        <swiper-slide
                            v-for="(item,index) in swiper"
                            :key="index">
                            <div class="advantage-swiper_item">
                                <div class="bg">
                                    <img :src="item.icon" class="advantage-swiper_img"/>
                                </div>
                                <div class="advantage-swiper_bottom">
                                    <span>{{item.title}}</span>
                                    <!-- <p>{{item.mome}}</p> -->
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="我们的产品" subtitle="Product"></partTitle>
                <span class="produce_span" v-class="'animate__animated animate__fadeInup animate__block'">乘客端</span>
                <div class="produce_div" v-class="'animate__animated animate__fadeInup animate__block'">
                    <swiper class="swiper second" :options="swiperOption1">
                        <swiper-slide
                            v-for="(item,index) in swiper1"
                            :key="index">
                            <div class="advantage-swiper_item">
                                <div class="bg">
                                    <img :src="item.icon" class="advantage-swiper_img"/>
                                </div>
                                <div class="advantage-swiper_bottom">
                                    <span>{{item.title}}</span>
                                    <p>{{item.mome}}</p>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination1"></div>
                </div>
                <span class="produce_span" v-class="'animate__animated animate__fadeInup animate__block'">司机端</span>
                <div class="produce_div" v-class="'animate__animated animate__fadeInup animate__block'">
                    <swiper class="swiper third" :options="swiperOption2">
                        <swiper-slide
                            v-for="(item,index) in swiper2"
                            :key="index">
                            <div class="advantage-swiper_item">
                                <div class="bg" >
                                    <img :src="item.icon" class="advantage-swiper_img"/>
                                </div>
                                <div class="advantage-swiper_bottom">
                                    <span>{{item.title}}</span>
                                    <p>{{item.mome}}</p>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination2"></div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <foot></foot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swiperOption:{
                pagination: '.swiper-pagination',
                paginationClickable: true,
                loop: true
            },
            swiperOption1:{
                pagination: '.swiper-pagination1',
                paginationClickable: true,
                loop: true
            },
            swiperOption2:{
                pagination: '.swiper-pagination2',
                paginationClickable: true,
                loop: true
            },
            logoFlag: false,
            maintitle: "快车",
            subtitle: "以快速的响应、实惠的价格，为用户提供便捷、高效的出行服务",
            imgSrc: require('@/assets/images/kc-bg.png'),
            flag: false,
            swiper:[
                {
                    title:'安全合规',
                    icon:require('../../../static/images/kc/one-img.png'),
                    mome:'出行时间由您掌控，下单更享快捷应答'
                },
                {
                    title:'拒绝溢价',
                    icon:require('../../../static/images/kc/two-img.png'),
                    mome:'轻触输入目的地，即可叫车出发'
                },
                {
                    title:'专职司机',
                    icon:require('../../../static/images/kc/three-img.png'),
                    mome:'全新车辆舒适体验，点对点接送'
                }
            ],
            swiper1:[
                {
                    title:'推荐地点，即刻出发',
                    icon:require('../../../static/images/kc/ck-one.png'),
                    mome:'拖动地图即可切换到适合上车的推荐地点，司机接驾更快，出行效率更高'
                },
                {
                    title:'便捷叫车，一触即发',
                    icon:require('../../../static/images/kc/ck-two.png'),
                    mome:'随时叫车出发或提前预约某一时间段行程需求，随时为您调度附近的车辆'
                },
                {
                    title:'路线透明，实时定位',
                    icon:require('../../../static/images/kc/ck-three.png'),
                    mome:'行程路线清晰，地图界面实时追踪车辆行驶路径'
                },
                {
                    title:'实时沟通，安全接驾',
                    icon:require('../../../static/images/kc/ck-four.png'),
                    mome:'通过发送实时消息、隐私虚拟号码随时与司机保持联系，安全出行更省心'
                },
                {
                    title:'一键开票，便捷报销',
                    icon:require('../../../static/images/kc/ck-five.png'),
                    mome:'通勤、商务出行一键提交即可轻松报销，支持纸质发票和电子发票'
                }
            ],
            swiper2:[
                {
                    title:'人脸识别，认证上岗',
                    icon:require('../../../static/images/kc/sj-one.png'),
                    mome:'司机出车需要进行AI人脸识别，身份认证通过后方能上线接单'
                },
                {
                    title:'界面清晰，操作便捷',
                    icon:require('../../../static/images/kc/sj-two.png'),
                    mome:'司机端界面简单清晰，行程一目了然，上手简单，操作便捷'
                },
                {
                    title:'优质订单，精准推送',
                    icon:require('../../../static/images/kc/sj-three.png'),
                    mome:'海量优质订单，智能推送算法，高效撮合司机和乘客，达到供需匹配'
                },
                {
                    title:'精准导航，高效接驾',
                    icon:require('../../../static/images/kc/sj-four.png'),
                    mome:'高精度地图定位，精准导航路线，即时通讯等保证司机更快找到乘客位置，高效接驾'
                },
                {
                    title:'收入明细，随时可查',
                    icon:require('../../../static/images/kc/sj-five.png'),
                    mome:'司机收入流水实时展示，统计细致，记录全面，明细清晰，随时可供查对'
                }
            ],
        }
    },
    mounted(){
        this.routerTo(3);
    }
}
</script>

<style>
.mobile_swiper {
    position: relative;
}
.swiper .advantage-swiper_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .43rem;
    color: #2D2D39;
}
.swiper .advantage-swiper_item .bg {
    background: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 .48rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom span {
    font-size: .36rem;
    margin: .5rem 0 .21rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom p {
    font-size: .24rem;
}
.swiper-pagination,
.swiper-pagination1,
.swiper-pagination2 {
    position: relative;
    display: flex;
    justify-content: center;
    margin: .45rem auto;
}
.swiper-pagination .swiper-pagination-bullet,
.swiper-pagination1 .swiper-pagination-bullet,
.swiper-pagination2 .swiper-pagination-bullet {
    margin: 0 .1rem;
    background: #CBCBCB;
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background: #676767!important;
    opacity: 1;
}
</style>
<style scoped>
.animate__block{
    visibility: visible !important;
}
.animate__animated{
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay:0.2s;
}
.animate__fadeInup{
    -webkit-animation-name: FadeInup;
    animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: .5s;
}
.express_cont {
    margin-bottom: 2rem;
}
.express .express_cont .cont {
    margin-top: .9rem;
    visibility: hidden;
}
.cont span {
    display: block;
    font-size: .36rem;
    font-weight: bold;
    margin-left: .48rem;
    margin-top: .48rem;
}
.first .bg,
.first .bg img {
    width: 5.4rem;
    height: 3.1rem;
}
.second .bg,
.third .bg {
    width: 6.56rem;
    height: 3.07rem;
}
.second .bg img,
.third .bg img {
    height: 2.19rem;
}
.produce_span, .produce_div {
    visibility: hidden;
}
.advantage-swiper_bottom span {
    font-weight: normal;
}
@keyframes FadeInup {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
        transform: translateY(30%);
    }
    100%{
        opacity:1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>